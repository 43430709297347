<template>
  <div class='login_wrap'>
      <div class="header">
           <div class="header_box">
               <img src="../../assets/img/logo.png" alt="">
           </div>
       </div>
       <div class="content">
            <div class="content_box">
                <!-- <div class="img_box"></div> -->
                <!-- <div class="img_box" :style="loginImg"></div> -->
                <div class="login_box">
                    <div class="title_wrap">
                        <h1>众和教育管理后台</h1>
                    </div>
                    <div class="col_wrap account_wrap">
                        <Input prefix="md-person" v-model="account" size="default" placeholder="请输入帐户名" clearable class="account_input" @on-blur="inputBlurFn('account')"/>
                        <span ref="accountError" class="error_msg"></span>
                    </div>
                    <div class="col_wrap password_wrap">
                        <Input prefix="md-key" type="password" v-model="password" size="default" placeholder="请输入密码" clearable class="password_input" @on-blur="inputBlurFn('password')"/>
                        <span ref="passwordError" class="error_msg"></span>
                    </div>
                    <div class="col_wrap verification_wrap">
                        <div class="verification_wrap_top">
                            <div class="left_box">
                                <Input v-model="verification" size="default" placeholder="验证码" clearable class="verfication_code_input" @on-enter="handleLoginFn" @on-blur="inputBlurFn('verification')"/>
                            </div>
                            <div class="right_box" >
                                <h3 @click="updateCodeFn" onselectstart="return false"  ref="verfication_view_code"></h3>
                            </div>
                        </div>
                        <span ref="verificationError" class="error_msg"></span>
                    </div>
                    <div class="col_wrap">
                        <Button  shape="circle" :disabled="loginDisable" type="success" id="login_btn" @click="handleLoginFn">登录</Button>
                    </div>
                </div>
            </div>
       </div>
       <div class="footer">

       </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Input,Button,} from 'view-design';
Vue.component('Input', Input);
Vue.component('Button', Button);
import { setToken } from '../../utils/auth';
import NET_PORT from "../../api/port.js"
export default {
  name:'login',
  components:{},
  props:{},
  data(){
    return {
        account:'',
        password:'',
        verification:'',
        loginDisable:false,
        // loginBg:require('../../assets/img/login_bg.jpg')
    }
  },
  watch:{},
  computed:{
      loginImg(){
          return "background-image:url('"+this.loginBg+"')"
      }
  },
  methods:{
      inputBlurFn(type){
            let msgObj={
                accountMsg:'账号不能为空',
                passwordMsg:'密码不能为空',
                verificationMsg:'验证码不正确',
            }

            if(type==='verification'){

                if(this[type].trim().toUpperCase() !== this.$refs.verfication_view_code.innerText.toUpperCase()){
                    this.$refs[type+'Error'].classList.add('show');
                    this.$refs[type+'Error'].innerText=msgObj[type+'Msg']

                }else{
                    this.$refs[type+'Error'].classList.remove('show');
                    this.$refs[type+'Error'].innerText=""
                }

            }else{
                if(this[type].trim()===''){
                    this.$refs[type+'Error'].classList.add('show');
                    this.$refs[type+'Error'].innerText=msgObj[type+'Msg']
                }else{
                    this.$refs[type+'Error'].classList.remove('show');
                    this.$refs[type+'Error'].innerText=""
                }
            }
      },

      updateCodeFn(){
        const allStr='0123456789abcdefghijklmnopqrstuvwxyz';
        let str='';
        for(let i=0;i<4;i++){
            str+=allStr[parseInt(Math.random()*allStr.length)]
        }
        this.$refs.verfication_view_code.innerText=str;
      },

      handleLoginFn(){

        this.inputBlurFn('account')
        this.inputBlurFn('password')
        this.inputBlurFn('verification')

        if(!this.account.trim()||!this.password.trim()||!this.verification.trim()||!this.verification.trim()||this.verification.trim().toUpperCase() !== this.$refs.verfication_view_code.innerText.toUpperCase()){
                this.$Message.error({
                        content:"请正确填写完必要信息！",
                        duration:2,
                })
        }else{
            //将按钮禁用，避免重复点击发送请求
            this.loginDisable=true;

            let data={
                username:this.account.trim(),
                password:this.password.trim()
            }

        //这里是本地模拟登录的代码
            // let user={};
            // if(data.username==='1'){
            //     user={
            //         username:'15775161688',
            //         personName:'陈管理',
            //         rolelevel:'1',
            //         department:'测试部',
            //     }
            // }else if(data.username==='2'){
            //     user={
            //         username:'13550682346',
            //         personName:'杨管理',
            //         rolelevel:'2',
            //         department:'测试部',
            //     }
            // }else{
            //     this.$Message.error({
            //             content:'账号或密码错误！',
            //             duration:2,
            //     })
            //     this.updateCodeFn();
            //     this.loginDisable=false;

            //     return
            // }


            // let token='token'
            

            // let project='众和培训项目系统';
            // let page='training_programs';

            // setToken(token)
            // this.$store.commit('setRoutes',user.rolelevel)
            // this.$store.commit('setUser',user.username);
            // this.$store.commit('setPersonName',user.personName);
            // this.$store.commit('setRole',user.rolelevel);
            // this.$store.commit('setDepartmentroom',user.department ? user.department : '');
            // this.$store.commit('setProject',project)

            // this.$store.state.routes.forEach((item,index)=>{
            //     this.$router.addRoute(item)
            // })

            // this.$Message.success({
            //     content:"登录成功!",
            //     duration:0.5,
            //     onClose:()=>{
            //         this.$router.push(page)
            //     }
            // });

            // this.loginDisable=true;

        //这里是发起网络请求登录的代码
            this.$axios.post(NET_PORT.login,data)
            .then((res)=>{
                if(res.data.code===200){
                    let token=res.data.data[0].token;
                    let user=res.data.data[0].user;

                    let project='众和培训项目系统';
                    let page='training_programs';
                    setToken(token)
                    this.$store.commit('setRoutes',user.rolelevel)
                    this.$store.commit('setUser',user.username);
                    this.$store.commit('setPersonName',user.personName);
                    this.$store.commit('setRole',user.rolelevel);
                    this.$store.commit('setDepartmentroom',user.department ? user.department : '');
                    this.$store.commit('setProject',project)

                    this.$store.state.routes.forEach((item,index)=>{
                        this.$router.addRoute(item)
                    })

                    this.$Message.success({
                        content:"登录成功!",
                        duration:0.5,
                        onClose:()=>{
                            this.$router.push(page)
                        }
                    });

                    this.loginDisable=true;

                }else{
                    this.$Message.error({
                            content:res.data.msg,
                            duration:2,
                    })
                    
                    this.updateCodeFn();
                    this.loginDisable=false;
                }
                       
            })
            .catch((err)=>{
                this.$Message.error({
                        content:"网络错误！",
                        duration:2,
                })
                this.updateCodeFn();
                this.loginDisable=false;
            })

        }

      },
  },
  created(){},
  mounted(){
    
    this.updateCodeFn();
    
  }
}
</script>
<style lang="scss" scoped>
    @import "./index.scss";
</style>